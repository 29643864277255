
/*-------------------------------------
        5. List-topuics
--------------------------------------*/

ul{
    padding-inline-start: 30px;
}
.list-topics-content {
    z-index: 0;
}
.list-topics-content ul li { display: inline-block;}

.single-list-topics-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 220px;
    height: 180px;
    background:#fff;
    border-radius: 3px;
    box-shadow: 0 0px 10px rgba(71,71,71,.2);
    -webkit-transition: .3s linear; 
    -moz-transition:.3s linear; 
    -ms-transition:.3s linear; 
    -o-transition:.3s linear;
    transition: .3s linear;
}
.single-list-topics-content h2>a { margin: 13px 0;}
/*.single-list-topics-content:last-child{margin-right: 0;}*/

.single-list-topics-icon [class^="flaticon-"]:before,.single-list-topics-icon [class*=" flaticon-"]:before,.single-list-topics-icon [class^="flaticon-"]:after,.single-list-topics-icon [class*=" flaticon-"]:after {font-size: 45px;color:#343a3f;}
.single-list-topics-content:hover .single-list-topics-icon [class^="flaticon-"]:before,.single-list-topics-content:hover .single-list-topics-icon [class*=" flaticon-"]:before,.single-list-topics-content:hover .single-list-topics-icon [class^="flaticon-"]:after,.single-list-topics-content:hover .single-list-topics-icon [class*=" flaticon-"]:after {color:rgb(160, 48, 48);}

.single-list-topics-content:hover h2>a,.single-list-topics-content:hover p{color: #fff!important;}
.single-list-topics-content:hover{
    color: #c2b9ac;
    background:#16335f;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition-duration: 500ms;
    box-shadow: 0 5px 10px rgba(71,71,71,.4);
}