/* Stil general pentru text */
.navbar-nav .nav-link,
.dropdown-menu .dropdown-item {
    font-family: Arial, sans-serif;
    font-size: 16px;
}

/* Stil pentru hover pe dropdown */
.dropdown-menu .dropdown-item:hover {
    background-color: #f8f8f8;
    color: #f1ebdd;
}

.dropdown-menu {
    margin-top: 0; /* Elimină decalajele care pot cauza probleme */
    padding: 5px; /* Ajustează pentru mai mult spațiu */
}

.dropdown:hover > .dropdown-menu {
    display: block; /* Asigură afișarea submeniului la hover */
}

/* Aliniere verticală a meniului */
.navbar-nav {
    align-items: center;
}

/* Linie dinamică sub meniul principal */
.navbar-nav .nav-link {
    position: relative;
    color: #5f5f5f; /* Culoare implicită */
    text-decoration: none;
}

.navbar-nav .nav-link:hover {
    color: #cca876 !important; /* Culoare la hover */
}

/* Stil pentru nav-item-custom */
.nav-item-custom {
    border: 2px solid #cca876;
    padding: 0px 30px;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: transparent; /* Fundal implicit */
}

.nav-item-custom a.nav-link-custom2 {
    color: #cca876 !important; /* Culoare text implicit */
    font-weight: bold !important;
    text-decoration: none;
    transition: color 0.3s ease; /* Efect de tranziție */
}

/* Hover efect pentru nav-item-custom */
.nav-item-custom:hover {
    background-color: #cca876; /* Fundal la hover */
    cursor: pointer;
}

.nav-item-custom:hover a.nav-link-custom2 {
    color: #fafafa !important; /* Text alb la hover */
}

/* Hover general pentru toate link-urile */
a:hover {
    color: #cca876;
    text-decoration: none;
}

/* Stil responsiv pentru hover */
@media only screen and (max-width: 768px) {
    a:hover {
        color: #cca876;
    }
}

/* Dropdown hover */
.navbar-nav .dropdown-menu {
    border: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.dropdown-menu .dropdown-item {
    color: #5f5f5f;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #aa9f89;
    color: #ffffff;
}

/* Linia activă din meniul principal */
.navbar-nav .nav-link.active {
    color: #aa9f89 !important;
    font-weight: bold;
}

/* Stiluri pentru telefon, email și fax */
.navbar-contact a {
    color: #5f5f5f;
    text-decoration: none;
    transition: color 0.3s ease;
}

.navbar-contact a:hover {
    color: #cca876;
}

/* Media Query pentru meniul responsive */
@media only screen and (max-width: 768px) {
    .nav-item-custom {
        padding: 5px 20px; /* Ajustare padding */
    }

    .navbar-nav .nav-link {
        font-size: 14px; /* Dimensiune text mai mică */
    }
}