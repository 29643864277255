.main-container {
    min-height: 100vh;
    background-color: #f3f4f6;
    padding: 2rem 1.5rem;
  }
  
  .main-title {
    font-size: 4vw; /* Textul se ajustează în funcție de lățimea ecranului */
    font-weight: bold;
    text-align: center;
    color: #1f2937;
    margin-bottom: 3rem;
  }
  
  .sections-container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .section-block {
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid #e5e7eb;
  }
  
  .cards-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem; /* This ensures equal spacing between items */
  }
  
  @media (max-width: 1200px) {
    .cards-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .cards-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .cards-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .card-link {
    display: block;
    text-decoration: none;
    border-radius: 0.75rem;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    margin: 0 !important;
  }
  
  .card-link:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .card-content {
    padding: 1.5rem;
    position: relative;
    z-index: 1;
  }
  
  .status-dot {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #10B981;
  }
  
  .room-number {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-bottom: 0.5rem;
    transition: transform 0.3s ease;
  }
  
  .card-link:hover .room-number {
    transform: scale(1.1);
  }
  
  .room-id {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    transition: opacity 0.3s ease;
  }
  
  .hover-text {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    font-size: 0.875rem;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0;
    transform: translateY(1rem);
    transition: all 0.3s ease;
  }
  
  .card-link:hover .hover-text {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Card Color Variations */
  .card-blue {
    background: linear-gradient(135deg, #2563EB, #1D4ED8);
  }
  
  .card-green {
    background: linear-gradient(135deg, #059669, #047857);
  }
  
  .card-purple {
    background: linear-gradient(135deg, #7C3AED, #6D28D9);
  }
  
  .card-gold {
    background: linear-gradient(135deg, #D97706, #B45309);
  }
  
  /* Hover Effects */
  .card-link::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .card-link:hover::after {
    opacity: 1;
  }
  
  .hovered {
    transform: translateY(-5px);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .responsive-title {
    font-size: 24px; /* Dimensiune implicită */
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .responsive-title {
      font-size: 18px; /* Dimensiune pentru tablete */
    }
  }
  
  @media (max-width: 480px) {
    .responsive-title {
      font-size: 16px; /* Dimensiune pentru telefoane */
    }
  }