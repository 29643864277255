
  .font-size--x--small {
    font-size: 10px;
  }

  .font-size--small {
    font-size: 14px;
  }
  
  .font-size--default {
    font-size: 19px;
  }
  
  .font-size--large {
    font-size: 24px;
  }

  .font-size--extra--x--large{
      font-size: 28px;
  }
  .font-size--extra--large {
    font-size: 32px;
  }

  .contrast{
      background-color: black;
      
  }
  .font-color{
    color:yellow;
  }