/* Blur effect */
.blurred-content {
  filter: blur(5px);
  pointer-events: none;
}

/* Fundalul întregului popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fundal semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Conținutul popup-ului */
.popup-content {
  background: #f9fafc; /* Fundal foarte deschis */
  border-radius: 30px; /* Colțuri rotunjite */
  padding: 4rem 4rem;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  max-width: 950px;
  width: 100%;
  position: relative;
  text-align: left;
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Titlul popup-ului */
.popup-title {
  font-family: "Times New Roman", serif;
  font-size: 3.5rem;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  color: #000; /* Negru */
  margin-bottom: 1rem;
}

/* Textul din popup */
.popup-content p {
  font-size: 1.1rem;
  line-height: 2;
  color: #555; /* Gri închis */
  margin: 0.5rem 0;
}

/* Link-uri stilizate */
.popup-content a {
  color: #cca876; /* Maro deschis pentru link-uri */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease; /* Efect de tranziție pentru hover */
}

.popup-content a:hover {
  color: #948973; /* Maro mai închis la hover */
  text-decoration: underline;
}

/* Butonul de închidere (X) */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #cca876 !important;/* Maro deschis */
  border: none;
  color: #fff; /* Alb pentru simbolul "X" */
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  transition: background 0.3s ease; /* Tranziție pentru hover */
}

.close-btn:hover {
  background: #948973; /* Maro mai închis la hover */
}