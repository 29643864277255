
.ListItemText span{
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #142038;
    font-weight: bold;
    padding-top: 5px;
}
.SublistItemText span{
    font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1600 - 300)));
    color: #142038;
    font-weight: bold;
    padding-top: 5px;
}
.TextCollapse span{
    text-align: justify;
    font-size: calc(11px + (16 - 11) * ((100vw - 300px) / (1600 - 300)));
}

.TextBeforeList span{
    text-align: justify;
    font-size: calc(11px + (16 - 11) * ((100vw - 300px) / (1600 - 300)));
    text-indent: 50px;
}

.titleListCompetente{
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #322d7a;
    font-weight: bolder;
}