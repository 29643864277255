body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* Pentru DGPR Snackbar */
#message-id{
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
}

/* For contact page */

.card-text{
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
}
.card-div p{
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
}