
/*====================================================
            CARD INSTANTA
======================================================*/

.cardContent h4{
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    color: #39b3ff;
}
.cardContent p{
    font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1600 - 300)));
}


/*====================================================
            ISTORICUL PALATULUI DE JUSTITIE
======================================================*/

#skills {
    background-image: url("../images/trcj.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.skill-cover {
    background-color: rgba(31, 31, 31, 0.68); 
}

.skill-cover h3{
  font-size: 35px;
  margin-bottom: 40px;
}

.skill-item {
    padding: 20px 0;
    margin-bottom: 30px;
    background: rgba(144, 144, 144, 0.295);
}

.skill-item i {
    color: #2196f3;
}

.skill-item h2 {
    color: #fff;
    font-size: calc(16px + (30 - 16) * ((100vw - 300px) / (1600 - 300)));
    margin-top: 15px;
    margin-bottom: 1px;
}

.skill-item p {
    color: #fff;
    /*text-transform: uppercase;*/
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    margin-top: 0;
}



.skill-item1 {
    padding: 20px 0;
    margin-bottom: 30px;
    background: rgba(0, 0, 0, 0.6);
}

.skill-item1 i {
    color: #2196f3;
}

.skill-item1 h2 {
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    margin-top: 65px;
    margin-bottom: 1000px;
}

.skill-item1 p {
    color: #fff;
    /*text-transform: uppercase;*/
    font-weight: 300;
    margin-top: 0;
}

/*====================================================
                      ISTORIC
======================================================*/



.subheading {
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Saira Extra Condensed', serif;
    font-size: 1.35rem;
}



#awards {
    background: #f0f0f0;
}

#award-box {
    overflow: hidden;
    position: relative;
    padding: 60px 0;
}

#award-box:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #cfcdcd;
    position: absolute;
    top: 0;
    left: 50%;
}

#award-box .award {
    width: 50%;
    clear: both;
    position: relative;
}

#award-box .award:before,
#award-box .award:after {
    content: "";
    display: block;
    clear: both;
}

#award-box .award:first-child:before,
#award-box .award:last-child:before {
    content: "";
    width: 11px;
    height: 11px;
    background: #cfcdcd;
    box-sizing: content-box;
    border: 5px solid #fff;
    box-shadow: 0 0 0 2px #cfcdcd;
    position: absolute;
    top: -54px;
    right: -11px;
    transform: rotate(45deg);
}

#award-box .award:last-child:before {
    top: auto;
    bottom: -54px;
}

#award-box .award:last-child:nth-child(even):before {
    right: auto;
    left: -11px;
}

#award-box .award-icon {
    width: 24px;
    height: 24px;
    background: rgb(169, 180, 182);
    border: 1px solid #cfcdcd;
    position: absolute;
    top: 17px;
    right: -13px;
    z-index: 1;
    transform: rotate(45deg);
}

#award-box .award-icon:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.2s ease 0s;
}

#award-box .award:hover .award-icon:before {
    background: #0c3046;
}

#award-box .award-content {
    width: 85%;
    padding: 18px 30px;
    background: #fff;
    text-align: right;
    float: left;
    border: 1px solid transparent;
    position: relative;
    transition: all 0.3s ease 0s;
}

#award-box .award:hover .award-content {
    border: 1px solid #cfcdcd;
}

#award-box .award-content:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background: rgb(170, 214, 243);
    border: 1px solid #cfcdcd;
    position: absolute;
    top: 21px;
    right: -7.3px;
    transform: rotate(45deg);
    transition: all 0.2s ease 0s;
}

#award-box .award:hover .award-content:before {
    background: #39b3ff;
    border-color: #082333;
}

#award-box .award-content:after {
    content: "";
    width: 11%;
    height: 1px;
    background: #cfcdcd;
    position: absolute;
    top: 28px;
    right: -14%;
}

#award-box .date {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #113144;
    margin: 0 0 8px;
    transition: all 0.3s ease 0s;
}

#award-box .award:hover .date {
    color: #444;
}

#award-box .title {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #444;
    margin-top: 0;
    transition: all 0.3s ease 0s;
}

#award-box .award:hover .title {
    color: #39b3ff;
}

#award-box .description {
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    color: #777;
    line-height: 28px;
    margin-top: 8px;
    text-align: justify;
}

#award-box .award:nth-child(2n),
#award-box .award:nth-child(2n) .award-content {
    float: right;
    text-align: left;
}

#award-box .award:nth-child(2n) .award-icon {
    right: 0;
    left: -12px;
}

#award-box .award:nth-child(2n) .award-content:before {
    left: -7.3px;
}

#award-box .award:nth-child(2n) .award-content:after {
    left: -14%;
}
 
@media only screen and (max-width: 767px) {
    #award-box {
        padding-left: 20px;
    }
    #award-box:before {
        left: 20px;
    }
    #award-box .award {
        width: 100%;
    }
    #award-box .award,
    #award-box .award-content {
        float: right;
        padding:5px;
        text-align: left;
    }
    #award-box .award:first-child:before,
    #award-box .award:last-child:nth-child(odd):before {
        right: auto;
        left: -11px;
    }
    #award-box .award-icon {
        right: 0;
        left: -12px;
    }
    #award-box .award-content:before {
        left: -7.3px;
    }
    #award-box .award-content:after {
        left: -14%;
    }
}
