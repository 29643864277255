body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* Pentru DGPR Snackbar */
#message-id{
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
}

/* For contact page */

.card-text{
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
}
.card-div p{
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
}

* {
  font-size: 19px ;
}

html, body {
  overflow-x: hidden;
}
.App {
  text-align: center;
  position: relative;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


.popup_title {
  text-align: center;
  margin: 1rem 0 2rem;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 0.5rem 0;
}

.popup_text {
  font-size: 1.2rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.telefon {
  list-style: none;
  margin: 0;
  padding: 0;
}

.telefon>li {
  display: inline-block;
  margin-right: 0.8rem;
  font-size: 1.2rem;
}

.telefon>li:last {
  margin-right: 0;
}

.blackText {
  color: black;
}

@media screen and (max-width: 840px) {
  .popup_title {
    font-size: 1.5rem;
  }

  .popup_text {
    font-size: 0.8rem;
  }

  .telefon>li {
    font-size: 0.8rem;
  }
}
/*used for:  Info utile & Legaturi utile */
.titleClass{
    font-size: calc(20px + (28 - 22) * ((100vw - 300px) / (1600 - 300)));
    color: #164164;
    font-weight: bold;
    text-align: center;
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius:5px;
    margin:0 auto;
}

/*used for:  Every page has a header-title component*/
.col h1{
    font-size: calc(20px + (27 - 20) * ((100vw - 300px) / (1600 - 300)));
  }
  .breadcrumbs ul li,
  .breadcrumbs ul li a{
    
    color: #978783;
  }
  /*  Image and title */
  .site-blocks-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top; }
    .site-blocks-cover.overlay {
      position: relative; }
      .site-blocks-cover.overlay:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4); }
    .site-blocks-cover, .site-blocks-cover .row {
      min-height: 200px;
      height: calc(50vh); }
    .site-blocks-cover.inner-page, .site-blocks-cover.inner-page .row {
      min-height: 200px;
      height: calc(40vh); }
    .site-blocks-cover .sub-text {
      font-size: 1.1rem;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.7);
      letter-spacing: .2em; }
   
    .site-blocks-cover p {
      color: #fff;
      font-size: 1.5rem;
      line-height: 1.5; }
    .site-blocks-cover .intro-text {
      font-size: 16px;
      line-height: 1.5; }
  
      /* Breadcrumbs */
      .breadcrumbs
      {
        margin-top: -2px;
      }
      .breadcrumbs ul li
      {
        display: inline-block;
        position: relative;
      }
      .breadcrumbs ul li:not(:last-child)::after
      {
        display: inline-block;
        position: relative;
        content: '/';
        margin-left: 7px;
        margin-right: 2px;
      }
      

/*====================================================
            CARD INSTANTA
======================================================*/

.cardContent h4{
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    color: #39b3ff;
}
.cardContent p{
    font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1600 - 300)));
}


/*====================================================
            ISTORICUL PALATULUI DE JUSTITIE
======================================================*/

#skills {
    background-image: url(/static/media/trcj.38cc9d68.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.skill-cover {
    background-color: rgba(31, 31, 31, 0.68); 
}

.skill-cover h3{
  font-size: 35px;
  margin-bottom: 40px;
}

.skill-item {
    padding: 20px 0;
    margin-bottom: 30px;
    background: rgba(144, 144, 144, 0.295);
}

.skill-item i {
    color: #2196f3;
}

.skill-item h2 {
    color: #fff;
    font-size: calc(16px + (30 - 16) * ((100vw - 300px) / (1600 - 300)));
    margin-top: 15px;
    margin-bottom: 1px;
}

.skill-item p {
    color: #fff;
    /*text-transform: uppercase;*/
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    margin-top: 0;
}



.skill-item1 {
    padding: 20px 0;
    margin-bottom: 30px;
    background: rgba(0, 0, 0, 0.6);
}

.skill-item1 i {
    color: #2196f3;
}

.skill-item1 h2 {
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    margin-top: 65px;
    margin-bottom: 1000px;
}

.skill-item1 p {
    color: #fff;
    /*text-transform: uppercase;*/
    font-weight: 300;
    margin-top: 0;
}

/*====================================================
                      ISTORIC
======================================================*/



.subheading {
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Saira Extra Condensed', serif;
    font-size: 1.35rem;
}



#awards {
    background: #f0f0f0;
}

#award-box {
    overflow: hidden;
    position: relative;
    padding: 60px 0;
}

#award-box:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #cfcdcd;
    position: absolute;
    top: 0;
    left: 50%;
}

#award-box .award {
    width: 50%;
    clear: both;
    position: relative;
}

#award-box .award:before,
#award-box .award:after {
    content: "";
    display: block;
    clear: both;
}

#award-box .award:first-child:before,
#award-box .award:last-child:before {
    content: "";
    width: 11px;
    height: 11px;
    background: #cfcdcd;
    box-sizing: content-box;
    border: 5px solid #fff;
    box-shadow: 0 0 0 2px #cfcdcd;
    position: absolute;
    top: -54px;
    right: -11px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

#award-box .award:last-child:before {
    top: auto;
    bottom: -54px;
}

#award-box .award:last-child:nth-child(even):before {
    right: auto;
    left: -11px;
}

#award-box .award-icon {
    width: 24px;
    height: 24px;
    background: rgb(169, 180, 182);
    border: 1px solid #cfcdcd;
    position: absolute;
    top: 17px;
    right: -13px;
    z-index: 1;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

#award-box .award-icon:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: background-color 0.2s ease 0s;
}

#award-box .award:hover .award-icon:before {
    background: #0c3046;
}

#award-box .award-content {
    width: 85%;
    padding: 18px 30px;
    background: #fff;
    text-align: right;
    float: left;
    border: 1px solid transparent;
    position: relative;
    transition: all 0.3s ease 0s;
}

#award-box .award:hover .award-content {
    border: 1px solid #cfcdcd;
}

#award-box .award-content:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background: rgb(170, 214, 243);
    border: 1px solid #cfcdcd;
    position: absolute;
    top: 21px;
    right: -7.3px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: all 0.2s ease 0s;
}

#award-box .award:hover .award-content:before {
    background: #39b3ff;
    border-color: #082333;
}

#award-box .award-content:after {
    content: "";
    width: 11%;
    height: 1px;
    background: #cfcdcd;
    position: absolute;
    top: 28px;
    right: -14%;
}

#award-box .date {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #113144;
    margin: 0 0 8px;
    transition: all 0.3s ease 0s;
}

#award-box .award:hover .date {
    color: #444;
}

#award-box .title {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #444;
    margin-top: 0;
    transition: all 0.3s ease 0s;
}

#award-box .award:hover .title {
    color: #39b3ff;
}

#award-box .description {
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    color: #777;
    line-height: 28px;
    margin-top: 8px;
    text-align: justify;
}

#award-box .award:nth-child(2n),
#award-box .award:nth-child(2n) .award-content {
    float: right;
    text-align: left;
}

#award-box .award:nth-child(2n) .award-icon {
    right: 0;
    left: -12px;
}

#award-box .award:nth-child(2n) .award-content:before {
    left: -7.3px;
}

#award-box .award:nth-child(2n) .award-content:after {
    left: -14%;
}
 
@media only screen and (max-width: 767px) {
    #award-box {
        padding-left: 20px;
    }
    #award-box:before {
        left: 20px;
    }
    #award-box .award {
        width: 100%;
    }
    #award-box .award,
    #award-box .award-content {
        float: right;
        padding:5px;
        text-align: left;
    }
    #award-box .award:first-child:before,
    #award-box .award:last-child:nth-child(odd):before {
        right: auto;
        left: -11px;
    }
    #award-box .award-icon {
        right: 0;
        left: -12px;
    }
    #award-box .award-content:before {
        left: -7.3px;
    }
    #award-box .award-content:after {
        left: -14%;
    }
}

/* Blur effect */
.blurred-content {
  -webkit-filter: blur(5px);
          filter: blur(5px);
  pointer-events: none;
}

/* Fundalul întregului popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fundal semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Conținutul popup-ului */
.popup-content {
  background: #f9fafc; /* Fundal foarte deschis */
  border-radius: 30px; /* Colțuri rotunjite */
  padding: 4rem 4rem;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  max-width: 950px;
  width: 100%;
  position: relative;
  text-align: left;
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Titlul popup-ului */
.popup-title {
  font-family: "Times New Roman", serif;
  font-size: 3.5rem;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  color: #000; /* Negru */
  margin-bottom: 1rem;
}

/* Textul din popup */
.popup-content p {
  font-size: 1.1rem;
  line-height: 2;
  color: #555; /* Gri închis */
  margin: 0.5rem 0;
}

/* Link-uri stilizate */
.popup-content a {
  color: #cca876; /* Maro deschis pentru link-uri */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease; /* Efect de tranziție pentru hover */
}

.popup-content a:hover {
  color: #948973; /* Maro mai închis la hover */
  text-decoration: underline;
}

/* Butonul de închidere (X) */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #cca876 !important;/* Maro deschis */
  border: none;
  color: #fff; /* Alb pentru simbolul "X" */
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  transition: background 0.3s ease; /* Tranziție pentru hover */
}

.close-btn:hover {
  background: #948973; /* Maro mai închis la hover */
}

/*-------------------------------------
        5. List-topuics
--------------------------------------*/

ul{
    -webkit-padding-start: 30px;
            padding-inline-start: 30px;
}
.list-topics-content {
    z-index: 0;
}
.list-topics-content ul li { display: inline-block;}

.single-list-topics-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 220px;
    height: 180px;
    background:#fff;
    border-radius: 3px;
    box-shadow: 0 0px 10px rgba(71,71,71,.2);
    transition: .3s linear;
}
.single-list-topics-content h2>a { margin: 13px 0;}
/*.single-list-topics-content:last-child{margin-right: 0;}*/

.single-list-topics-icon [class^="flaticon-"]:before,.single-list-topics-icon [class*=" flaticon-"]:before,.single-list-topics-icon [class^="flaticon-"]:after,.single-list-topics-icon [class*=" flaticon-"]:after {font-size: 45px;color:#343a3f;}
.single-list-topics-content:hover .single-list-topics-icon [class^="flaticon-"]:before,.single-list-topics-content:hover .single-list-topics-icon [class*=" flaticon-"]:before,.single-list-topics-content:hover .single-list-topics-icon [class^="flaticon-"]:after,.single-list-topics-content:hover .single-list-topics-icon [class*=" flaticon-"]:after {color:rgb(160, 48, 48);}

.single-list-topics-content:hover h2>a,.single-list-topics-content:hover p{color: #fff!important;}
.single-list-topics-content:hover{
    color: #c2b9ac;
    background:#16335f;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transition-duration: 500ms;
    box-shadow: 0 5px 10px rgba(71,71,71,.4);
}
.container__document {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.container__document .react-pdf__Document {
  display: inline-block;
}

.container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}
#appBarPdf{
  opacity: 0.8;
}
#appBarPdf:hover {
  opacity: 1; /* Safari */
  transition: opacity 0.3s;
}

body{background:#f5f5f5}
ul{padding:0; margin:0; list-style:none}
a{text-decoration:none; color:#2f2f2f}
a:hover{color:#646464; text-decoration:none}
a:focus{outline:none; text-decoration:none}

.single_sidebar > h2 span{ background:none repeat scroll 0 0 #d083cf}
.single_sidebar > ul > li a:hover{color:#d083cf}

.personalSectie span{
    font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
    color: #322d7a;
    font-weight: bolder;
}

.personalSubsectii span{
    font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #051035;
    font-weight: 400;
    margin-top: 1%;
    margin-bottom: 0em;

}

.personalAngajat span{
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    color:black;
}

.organizareDiv h2{
    font-size: calc(18px + (25 - 18) * ((100vw - 300px) / (1600 - 300)));
    padding-top: 25px;
    font-weight: bold;
    text-align: center;
    color: #4a63b6;
    
}
.organizareDiv img{
    max-width: 100%;
    border-radius: 30px;
}

.ListItemText span{
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #142038;
    font-weight: bold;
    padding-top: 5px;
}
.SublistItemText span{
    font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1600 - 300)));
    color: #142038;
    font-weight: bold;
    padding-top: 5px;
}
.TextCollapse span{
    text-align: justify;
    font-size: calc(11px + (16 - 11) * ((100vw - 300px) / (1600 - 300)));
}

.TextBeforeList span{
    text-align: justify;
    font-size: calc(11px + (16 - 11) * ((100vw - 300px) / (1600 - 300)));
    text-indent: 50px;
}

.titleListCompetente{
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #322d7a;
    font-weight: bolder;
}
/* W3.CSS 4.13 June 2019 by Jan Egil and Borge Refsnes */
html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html{-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body{margin:0}
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section{display:block}summary{display:list-item}
audio,canvas,progress,video{display:inline-block}progress{vertical-align:baseline}
audio:not([controls]){display:none;height:0}[hidden],template{display:none}
a{background-color:transparent}a:active,a:hover{outline-width:0}
abbr[title]{border-bottom:none;text-decoration:underline;-webkit-text-decoration:underline dotted;text-decoration:underline dotted}
b,strong{font-weight:bolder}dfn{font-style:italic}mark{background:#ff0;color:#000}
small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sub{bottom:-0.25em}sup{top:-0.5em}figure{margin:1em 40px}img{border-style:none}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}hr{box-sizing:content-box;height:0;overflow:visible}
button,input,select,textarea,optgroup{font:inherit;margin:0}optgroup{font-weight:bold}
button,input{overflow:visible}button,select{text-transform:none}
button,[type=button],[type=reset],[type=submit]{-webkit-appearance:button}
button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner{border-style:none;padding:0}
button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring{outline:1px dotted ButtonText}
fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:.35em .625em .75em}
legend{color:inherit;display:table;max-width:100%;padding:0;white-space:normal}textarea{overflow:auto}
[type=checkbox],[type=radio]{padding:0}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}
[type=search]{-webkit-appearance:textfield;outline-offset:-2px}
[type=search]::-webkit-search-decoration{-webkit-appearance:none}
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
/* End extract */
html,body{font-family:Verdana,sans-serif;font-size:15px;line-height:1.5}html{overflow-x:hidden}
h1{font-size:36px}h2{font-size:30px}h3{font-size:24px}h4{font-size:20px}h5{font-size:18px}h6{font-size:16px}.w3-serif{font-family:serif}
h1,h2,h3,h4,h5,h6{font-family:"Segoe UI",Arial,sans-serif;font-weight:400;margin:10px 0}.w3-wide{letter-spacing:4px}
hr{border:0;border-top:1px solid #eee;margin:20px 0}
.w3-image{max-width:100%;height:auto}img{vertical-align:middle}a{color:inherit}
.w3-table,.w3-table-all{border-collapse:collapse;border-spacing:0;width:100%;display:table}.w3-table-all{border:1px solid #ccc}
.w3-bordered tr,.w3-table-all tr{border-bottom:1px solid #ddd}.w3-striped tbody tr:nth-child(even){background-color:#f1f1f1}
.w3-table-all tr:nth-child(odd){background-color:#fff}.w3-table-all tr:nth-child(even){background-color:#f1f1f1}
.w3-hoverable tbody tr:hover,.w3-ul.w3-hoverable li:hover{background-color:#ccc}.w3-centered tr th,.w3-centered tr td{text-align:center}
.w3-table td,.w3-table th,.w3-table-all td,.w3-table-all th{padding:8px 8px;display:table-cell;text-align:left;vertical-align:top}
.w3-table th:first-child,.w3-table td:first-child,.w3-table-all th:first-child,.w3-table-all td:first-child{padding-left:16px}
.w3-btn,.w3-button{border:none;display:inline-block;padding:8px 16px;vertical-align:middle;overflow:hidden;text-decoration:none;color:inherit;background-color:inherit;text-align:center;cursor:pointer;white-space:nowrap}
.w3-btn:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)}
.w3-btn,.w3-button{-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}   
.w3-disabled,.w3-btn:disabled,.w3-button:disabled{cursor:not-allowed;opacity:0.3}.w3-disabled *,:disabled *{pointer-events:none}
.w3-btn.w3-disabled:hover,.w3-btn:disabled:hover{box-shadow:none}
.w3-badge,.w3-tag{background-color:#000;color:#fff;display:inline-block;padding-left:8px;padding-right:8px;text-align:center}.w3-badge{border-radius:50%}
.w3-ul{list-style-type:none;padding:0;margin:0}.w3-ul li{padding:8px 16px;border-bottom:1px solid #ddd}.w3-ul li:last-child{border-bottom:none}
.w3-tooltip,.w3-display-container{position:relative}.w3-tooltip .w3-text{display:none}.w3-tooltip:hover .w3-text{display:inline-block}
.w3-ripple:active{opacity:0.5}.w3-ripple{transition:opacity 0s}
.w3-input{padding:8px;display:block;border:none;border-bottom:1px solid #ccc;width:100%}
.w3-select{padding:9px 0;width:100%;border:none;border-bottom:1px solid #ccc}
.w3-dropdown-click,.w3-dropdown-hover{position:relative;display:inline-block;cursor:pointer}
.w3-dropdown-hover:hover .w3-dropdown-content{display:block}
.w3-dropdown-hover:first-child,.w3-dropdown-click:hover{background-color:#ccc;color:#000}
.w3-dropdown-hover:hover > .w3-button:first-child,.w3-dropdown-click:hover > .w3-button:first-child{background-color:#ccc;color:#000}
.w3-dropdown-content{cursor:auto;color:#000;background-color:#fff;display:none;position:absolute;min-width:160px;margin:0;padding:0;z-index:1}
.w3-check,.w3-radio{width:24px;height:24px;position:relative;top:6px}
.w3-sidebar{height:100%;width:200px;background-color:#fff;position:fixed!important;z-index:1;overflow:auto}
.w3-bar-block .w3-dropdown-hover,.w3-bar-block .w3-dropdown-click{width:100%}
.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,.w3-bar-block .w3-dropdown-click .w3-dropdown-content{min-width:100%}
.w3-bar-block .w3-dropdown-hover .w3-button,.w3-bar-block .w3-dropdown-click .w3-button{width:100%;text-align:left;padding:8px 16px}
.w3-main,#main{transition:margin-left .4s}
.w3-modal{z-index:3;display:none;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.w3-modal-content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;width:600px}
.w3-bar{width:100%;overflow:hidden}.w3-center .w3-bar{display:inline-block;width:auto}
.w3-bar .w3-bar-item{padding:8px 16px;float:left;width:auto;border:none;display:block;outline:0}
.w3-bar .w3-dropdown-hover,.w3-bar .w3-dropdown-click{position:static;float:left}
.w3-bar .w3-button{white-space:normal}
.w3-bar-block .w3-bar-item{width:100%;display:block;padding:8px 16px;text-align:left;border:none;white-space:normal;float:none;outline:0}
.w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}
.w3-responsive{display:block;overflow-x:auto}
.w3-container:after,.w3-container:before,.w3-panel:after,.w3-panel:before,.w3-row:after,.w3-row:before,.w3-row-padding:after,.w3-row-padding:before,
.w3-cell-row:before,.w3-cell-row:after,.w3-clear:after,.w3-clear:before,.w3-bar:before,.w3-bar:after{content:"";display:table;clear:both}
.w3-col,.w3-half,.w3-third,.w3-twothird,.w3-threequarter,.w3-quarter{float:left;width:100%}
.w3-col.s1{width:8.33333%}.w3-col.s2{width:16.66666%}.w3-col.s3{width:24.99999%}.w3-col.s4{width:33.33333%}
.w3-col.s5{width:41.66666%}.w3-col.s6{width:49.99999%}.w3-col.s7{width:58.33333%}.w3-col.s8{width:66.66666%}
.w3-col.s9{width:74.99999%}.w3-col.s10{width:83.33333%}.w3-col.s11{width:91.66666%}.w3-col.s12{width:99.99999%}
@media (min-width:601px){.w3-col.m1{width:8.33333%}.w3-col.m2{width:16.66666%}.w3-col.m3,.w3-quarter{width:24.99999%}.w3-col.m4,.w3-third{width:10%}
.w3-col.m5{width:41.66666%}.w3-col.m6,.w3-half{width:49.99999%}.w3-col.m7{width:58.33333%}.w3-col.m8,.w3-twothird{width:100%}
.w3-col.m9,.w3-threequarter{width:74.99999%}.w3-col.m10{width:83.33333%}.w3-col.m11{width:91.66666%}.w3-col.m12{width:99.99999%}}
@media (min-width:993px){.w3-col.l1{width:8.33333%}.w3-col.l2{width:16.66666%}.w3-col.l3{width:24.99999%}.w3-col.l4{width:33.33333%}
.w3-col.l5{width:41.66666%}.w3-col.l6{width:49.99999%}.w3-col.l7{width:58.33333%}.w3-col.l8{width:66.66666%}
.w3-col.l9{width:74.99999%}.w3-col.l10{width:83.33333%}.w3-col.l11{width:91.66666%}.w3-col.l12{width:99.99999%}}
.w3-rest{overflow:hidden}.w3-stretch{margin-left:-16px;margin-right:-16px}
.w3-content,.w3-auto{margin-left:auto;margin-right:auto}.w3-content{max-width:980px}.w3-auto{max-width:1140px}
.w3-cell-row{display:table;width:100%}.w3-cell{display:table-cell}
.w3-cell-top{vertical-align:top}.w3-cell-middle{vertical-align:middle}.w3-cell-bottom{vertical-align:bottom}
.w3-hide{display:none!important}.w3-show-block,.w3-show{display:block!important}.w3-show-inline-block{display:inline-block!important}
@media (max-width:1205px){.w3-auto{max-width:95%}}
@media (max-width:600px){.w3-modal-content{margin:0 10px;width:auto!important}.w3-modal{padding-top:30px}
.w3-dropdown-hover.w3-mobile .w3-dropdown-content,.w3-dropdown-click.w3-mobile .w3-dropdown-content{position:relative}	
.w3-hide-small{display:none!important}.w3-mobile{display:block;width:100%!important}.w3-bar-item.w3-mobile,.w3-dropdown-hover.w3-mobile,.w3-dropdown-click.w3-mobile{text-align:center}
.w3-dropdown-hover.w3-mobile,.w3-dropdown-hover.w3-mobile .w3-btn,.w3-dropdown-hover.w3-mobile .w3-button,.w3-dropdown-click.w3-mobile,.w3-dropdown-click.w3-mobile .w3-btn,.w3-dropdown-click.w3-mobile .w3-button{width:100%}}
@media (max-width:768px){.w3-modal-content{width:500px}.w3-modal{padding-top:50px}}
@media (min-width:993px){.w3-modal-content{width:900px}.w3-hide-large{display:none!important}.w3-sidebar.w3-collapse{display:block!important}}
@media (max-width:992px) and (min-width:601px){.w3-hide-medium{display:none!important}}
@media (max-width:992px){.w3-sidebar.w3-collapse{display:none}.w3-main{margin-left:0!important;margin-right:0!important}.w3-auto{max-width:100%}}
.w3-top,.w3-bottom{position:fixed;width:100%;z-index:1}.w3-top{top:0}.w3-bottom{bottom:0}
.w3-overlay{position:fixed;display:none;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);z-index:2}
.w3-display-topleft{position:absolute;left:0;top:0}.w3-display-topright{position:absolute;right:0;top:0}
.w3-display-bottomleft{position:absolute;left:0;bottom:0}.w3-display-bottomright{position:absolute;right:0;bottom:0}
.w3-display-middle{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%)}
.w3-display-left{position:absolute;top:50%;left:0%;-webkit-transform:translate(0%,-50%);transform:translate(0%,-50%);-ms-transform:translate(-0%,-50%)}
.w3-display-right{position:absolute;top:50%;right:0%;-webkit-transform:translate(0%,-50%);transform:translate(0%,-50%);-ms-transform:translate(0%,-50%)}
.w3-display-topmiddle{position:absolute;left:50%;top:0;-webkit-transform:translate(-50%,0%);transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-bottommiddle{position:absolute;left:50%;bottom:0;-webkit-transform:translate(-50%,0%);transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-container:hover .w3-display-hover{display:block}.w3-display-container:hover span.w3-display-hover{display:inline-block}.w3-display-hover{display:none}
.w3-display-position{position:absolute}
.w3-circle{border-radius:50%}
.w3-round-small{border-radius:2px}.w3-round,.w3-round-medium{border-radius:4px}.w3-round-large{border-radius:8px}.w3-round-xlarge{border-radius:16px}.w3-round-xxlarge{border-radius:32px}
.w3-row-padding,.w3-row-padding>.w3-half,.w3-row-padding>.w3-third,.w3-row-padding>.w3-twothird,.w3-row-padding>.w3-threequarter,.w3-row-padding>.w3-quarter,.w3-row-padding>.w3-col{padding:0 8px}
.w3-container,.w3-panel{padding:0.01em 16px}.w3-panel{margin-top:16px;margin-bottom:16px}
.w3-code,.w3-codespan{font-family:Consolas,"courier new";font-size:16px}
.w3-code{width:auto;background-color:#fff;padding:8px 12px;border-left:4px solid #4CAF50;word-wrap:break-word}
.w3-codespan{color:crimson;background-color:#f1f1f1;padding-left:4px;padding-right:4px;font-size:110%}
.w3-card,.w3-card-2{box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)}
.w3-card-4,.w3-hover-shadow:hover{box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)}
.w3-spin{-webkit-animation:w3-spin 2s infinite linear;animation:w3-spin 2s infinite linear}@-webkit-keyframes w3-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}@keyframes w3-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}
.w3-animate-fading{-webkit-animation:fading 10s infinite;animation:fading 10s infinite}@-webkit-keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.w3-animate-opacity{-webkit-animation:opac 0.8s;animation:opac 0.8s}@-webkit-keyframes opac{from{opacity:0} to{opacity:1}}@keyframes opac{from{opacity:0} to{opacity:1}}
.w3-animate-top{position:relative;-webkit-animation:animatetop 0.4s;animation:animatetop 0.4s}@-webkit-keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
.w3-animate-left{position:relative;-webkit-animation:animateleft 0.4s;animation:animateleft 0.4s}@-webkit-keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;-webkit-animation:animateright 0.4s;animation:animateright 0.4s}@-webkit-keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.w3-animate-bottom{position:relative;-webkit-animation:animatebottom 0.4s;animation:animatebottom 0.4s}@-webkit-keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.w3-animate-zoom {-webkit-animation:animatezoom 0.6s;animation:animatezoom 0.6s}@-webkit-keyframes animatezoom{from{-webkit-transform:scale(0);transform:scale(0)} to{-webkit-transform:scale(1);transform:scale(1)}}@keyframes animatezoom{from{-webkit-transform:scale(0);transform:scale(0)} to{-webkit-transform:scale(1);transform:scale(1)}}
.w3-animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
.w3-opacity,.w3-hover-opacity:hover{opacity:0.60}.w3-opacity-off,.w3-hover-opacity-off:hover{opacity:1}
.w3-opacity-max{opacity:0.25}.w3-opacity-min{opacity:0.75}
.w3-greyscale-max,.w3-grayscale-max,.w3-hover-greyscale:hover,.w3-hover-grayscale:hover{-webkit-filter:grayscale(100%);filter:grayscale(100%)}
.w3-greyscale,.w3-grayscale{-webkit-filter:grayscale(75%);filter:grayscale(75%)}.w3-greyscale-min,.w3-grayscale-min{-webkit-filter:grayscale(50%);filter:grayscale(50%)}
.w3-sepia{-webkit-filter:sepia(75%);filter:sepia(75%)}.w3-sepia-max,.w3-hover-sepia:hover{-webkit-filter:sepia(100%);filter:sepia(100%)}.w3-sepia-min{-webkit-filter:sepia(50%);filter:sepia(50%)}
.w3-tiny{font-size:10px!important}.w3-small{font-size:12px!important}.w3-medium{font-size:15px!important}.w3-large{font-size:18px!important}
.w3-xlarge{font-size:24px!important}.w3-xxlarge{font-size:36px!important}.w3-xxxlarge{font-size:48px!important}.w3-jumbo{font-size:64px!important}
.w3-left-align{text-align:left!important}.w3-right-align{text-align:right!important}.w3-justify{text-align:justify!important}.w3-center{text-align:center!important}
.w3-border-0{border:0!important}.w3-border{border:1px solid #ccc!important}
.w3-border-top{border-top:1px solid #ccc!important}.w3-border-bottom{border-bottom:1px solid #ccc!important}
.w3-border-left{border-left:1px solid #ccc!important}.w3-border-right{border-right:1px solid #ccc!important}
.w3-topbar{border-top:6px solid #ccc!important}.w3-bottombar{border-bottom:6px solid #ccc!important}
.w3-leftbar{border-left:6px solid #ccc!important}.w3-rightbar{border-right:6px solid #ccc!important}
.w3-section,.w3-code{margin-top:16px!important;margin-bottom:16px!important}
.w3-margin{margin:16px!important}.w3-margin-top{margin-top:16px!important}.w3-margin-bottom{margin-bottom:16px!important}
.w3-margin-left{margin-left:16px!important}.w3-margin-right{margin-right:16px!important}
.w3-padding-small{padding:4px 8px!important}.w3-padding{padding:8px 16px!important}.w3-padding-large{padding:12px 24px!important}
.w3-padding-16{padding-top:16px!important;padding-bottom:16px!important}.w3-padding-24{padding-top:24px!important;padding-bottom:24px!important}
.w3-padding-32{padding-top:32px!important;padding-bottom:32px!important}.w3-padding-48{padding-top:48px!important;padding-bottom:48px!important}
.w3-padding-64{padding-top:64px!important;padding-bottom:64px!important}
.w3-left{float:left!important}.w3-right{float:right!important}
.w3-button:hover{color:#000!important;background-color:#ccc!important}
.w3-transparent,.w3-hover-none:hover{background-color:transparent!important}
.w3-hover-none:hover{box-shadow:none!important}
/* Colors */
.w3-amber,.w3-hover-amber:hover{color:#000!important;background-color:#ffc107!important}
.w3-aqua,.w3-hover-aqua:hover{color:#000!important;background-color:#00ffff!important}
.w3-blue,.w3-hover-blue:hover{color:#fff!important;background-color:#2196F3!important}
.w3-light-blue,.w3-hover-light-blue:hover{color:#000!important;background-color:#87CEEB!important}
.w3-brown,.w3-hover-brown:hover{color:#fff!important;background-color:#795548!important}
.w3-cyan,.w3-hover-cyan:hover{color:#000!important;background-color:#00bcd4!important}
.w3-blue-grey,.w3-hover-blue-grey:hover,.w3-blue-gray,.w3-hover-blue-gray:hover{color:#fff!important;background-color:#607d8b!important}
.w3-green,.w3-hover-green:hover{color:#fff!important;background-color:#4CAF50!important}
.w3-light-green,.w3-hover-light-green:hover{color:#000!important;background-color:#8bc34a!important}
.w3-indigo,.w3-hover-indigo:hover{color:#fff!important;background-color:#3f51b5!important}
.w3-khaki,.w3-hover-khaki:hover{color:#000!important;background-color:#f0e68c!important}
.w3-lime,.w3-hover-lime:hover{color:#000!important;background-color:#cddc39!important}
.w3-orange,.w3-hover-orange:hover{color:#000!important;background-color:#ff9800!important}
.w3-deep-orange,.w3-hover-deep-orange:hover{color:#fff!important;background-color:#ff5722!important}
.w3-pink,.w3-hover-pink:hover{color:#fff!important;background-color:#e91e63!important}
.w3-purple,.w3-hover-purple:hover{color:#fff!important;background-color:#9c27b0!important}
.w3-deep-purple,.w3-hover-deep-purple:hover{color:#fff!important;background-color:#673ab7!important}
.w3-red,.w3-hover-red:hover{color:#fff!important;background-color:#f44336!important}
.w3-sand,.w3-hover-sand:hover{color:#000!important;background-color:#fdf5e6!important}
.w3-teal,.w3-hover-teal:hover{color:#fff!important;background-color:#009688!important}
.w3-yellow,.w3-hover-yellow:hover{color:#000!important;background-color:#ffeb3b!important}
.w3-white,.w3-hover-white:hover{color:#000!important;background-color:#fff!important}
.w3-black,.w3-hover-black:hover{color:#fff!important;background-color:#000!important}
.w3-grey,.w3-hover-grey:hover,.w3-gray,.w3-hover-gray:hover{color:#000!important;background-color:#9e9e9e!important}
.w3-light-grey,.w3-hover-light-grey:hover,.w3-light-gray,.w3-hover-light-gray:hover{color:#000!important;background-color:#f1f1f1!important}
.w3-dark-grey,.w3-hover-dark-grey:hover,.w3-dark-gray,.w3-hover-dark-gray:hover{color:#fff!important;background-color:#616161!important}
.w3-pale-red,.w3-hover-pale-red:hover{color:#000!important;background-color:#ffdddd!important}
.w3-pale-green,.w3-hover-pale-green:hover{color:#000!important;background-color:#ddffdd!important}
.w3-pale-yellow,.w3-hover-pale-yellow:hover{color:#000!important;background-color:#ffffcc!important}
.w3-pale-blue,.w3-hover-pale-blue:hover{color:#000!important;background-color:#ddffff!important}
.w3-text-amber,.w3-hover-text-amber:hover{color:#ffc107!important}
.w3-text-aqua,.w3-hover-text-aqua:hover{color:#00ffff!important}
.w3-text-blue,.w3-hover-text-blue:hover{color:rgb(12, 14, 116)!important}
.w3-text-light-blue,.w3-hover-text-light-blue:hover{color:#87CEEB!important}
.w3-text-brown,.w3-hover-text-brown:hover{color:#795548!important}
.w3-text-cyan,.w3-hover-text-cyan:hover{color:#00bcd4!important}
.w3-text-blue-grey,.w3-hover-text-blue-grey:hover,.w3-text-blue-gray,.w3-hover-text-blue-gray:hover{color:#607d8b!important}
.w3-text-green,.w3-hover-text-green:hover{color:#4CAF50!important}
.w3-text-light-green,.w3-hover-text-light-green:hover{color:#8bc34a!important}
.w3-text-indigo,.w3-hover-text-indigo:hover{color:#3f51b5!important}
.w3-text-khaki,.w3-hover-text-khaki:hover{color:#b4aa50!important}
.w3-text-lime,.w3-hover-text-lime:hover{color:#cddc39!important}
.w3-text-orange,.w3-hover-text-orange:hover{color:#ff9800!important}
.w3-text-deep-orange,.w3-hover-text-deep-orange:hover{color:#ff5722!important}
.w3-text-pink,.w3-hover-text-pink:hover{color:#e91e63!important}
.w3-text-purple,.w3-hover-text-purple:hover{color:#9c27b0!important}
.w3-text-deep-purple,.w3-hover-text-deep-purple:hover{color:#673ab7!important}
.w3-text-red,.w3-hover-text-red:hover{color:#ca1d11!important}
.w3-text-sand,.w3-hover-text-sand:hover{color:#fdf5e6!important}
.w3-text-teal,.w3-hover-text-teal:hover{color:#009688!important}
.w3-text-yellow,.w3-hover-text-yellow:hover{color:#d2be0e!important}
.w3-text-white,.w3-hover-text-white:hover{color:#fff!important}
.w3-text-black,.w3-hover-text-black:hover{color:#000!important}
.w3-text-grey,.w3-hover-text-grey:hover,.w3-text-gray,.w3-hover-text-gray:hover{color:#757575!important}
.w3-text-light-grey,.w3-hover-text-light-grey:hover,.w3-text-light-gray,.w3-hover-text-light-gray:hover{color:#f1f1f1!important}
.w3-text-dark-grey,.w3-hover-text-dark-grey:hover,.w3-text-dark-gray,.w3-hover-text-dark-gray:hover{color:#3a3a3a!important}
.w3-border-amber,.w3-hover-border-amber:hover{border-color:#ffc107!important}
.w3-border-aqua,.w3-hover-border-aqua:hover{border-color:#00ffff!important}
.w3-border-blue,.w3-hover-border-blue:hover{border-color:#2196F3!important}
.w3-border-light-blue,.w3-hover-border-light-blue:hover{border-color:#87CEEB!important}
.w3-border-brown,.w3-hover-border-brown:hover{border-color:#795548!important}
.w3-border-cyan,.w3-hover-border-cyan:hover{border-color:#00bcd4!important}
.w3-border-blue-grey,.w3-hover-border-blue-grey:hover,.w3-border-blue-gray,.w3-hover-border-blue-gray:hover{border-color:#607d8b!important}
.w3-border-green,.w3-hover-border-green:hover{border-color:#4CAF50!important}
.w3-border-light-green,.w3-hover-border-light-green:hover{border-color:#8bc34a!important}
.w3-border-indigo,.w3-hover-border-indigo:hover{border-color:#3f51b5!important}
.w3-border-khaki,.w3-hover-border-khaki:hover{border-color:#f0e68c!important}
.w3-border-lime,.w3-hover-border-lime:hover{border-color:#cddc39!important}
.w3-border-orange,.w3-hover-border-orange:hover{border-color:#ff9800!important}
.w3-border-deep-orange,.w3-hover-border-deep-orange:hover{border-color:#ff5722!important}
.w3-border-pink,.w3-hover-border-pink:hover{border-color:#e91e63!important}
.w3-border-purple,.w3-hover-border-purple:hover{border-color:#9c27b0!important}
.w3-border-deep-purple,.w3-hover-border-deep-purple:hover{border-color:#673ab7!important}
.w3-border-red,.w3-hover-border-red:hover{border-color:#f44336!important}
.w3-border-sand,.w3-hover-border-sand:hover{border-color:#fdf5e6!important}
.w3-border-teal,.w3-hover-border-teal:hover{border-color:#009688!important}
.w3-border-yellow,.w3-hover-border-yellow:hover{border-color:#ffeb3b!important}
.w3-border-white,.w3-hover-border-white:hover{border-color:#fff!important}
.w3-border-black,.w3-hover-border-black:hover{border-color:#000!important}
.w3-border-grey,.w3-hover-border-grey:hover,.w3-border-gray,.w3-hover-border-gray:hover{border-color:#9e9e9e!important}
.w3-border-light-grey,.w3-hover-border-light-grey:hover,.w3-border-light-gray,.w3-hover-border-light-gray:hover{border-color:#f1f1f1!important}
.w3-border-dark-grey,.w3-hover-border-dark-grey:hover,.w3-border-dark-gray,.w3-hover-border-dark-gray:hover{border-color:#616161!important}
.w3-border-pale-red,.w3-hover-border-pale-red:hover{border-color:#ffe7e7!important}.w3-border-pale-green,.w3-hover-border-pale-green:hover{border-color:#e7ffe7!important}
.w3-border-pale-yellow,.w3-hover-border-pale-yellow:hover{border-color:#ffffcc!important}.w3-border-pale-blue,.w3-hover-border-pale-blue:hover{border-color:#e7ffff!important}
.main-container {
    min-height: 100vh;
    background-color: #f3f4f6;
    padding: 2rem 1.5rem;
  }
  
  .main-title {
    font-size: 4vw; /* Textul se ajustează în funcție de lățimea ecranului */
    font-weight: bold;
    text-align: center;
    color: #1f2937;
    margin-bottom: 3rem;
  }
  
  .sections-container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  .section-block {
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid #e5e7eb;
  }
  
  .cards-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem; /* This ensures equal spacing between items */
  }
  
  @media (max-width: 1200px) {
    .cards-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .cards-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .cards-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .card-link {
    display: block;
    text-decoration: none;
    border-radius: 0.75rem;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    margin: 0 !important;
  }
  
  .card-link:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .card-content {
    padding: 1.5rem;
    position: relative;
    z-index: 1;
  }
  
  .status-dot {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #10B981;
  }
  
  .room-number {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-bottom: 0.5rem;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  
  .card-link:hover .room-number {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .room-id {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    transition: opacity 0.3s ease;
  }
  
  .hover-text {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    font-size: 0.875rem;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0;
    -webkit-transform: translateY(1rem);
            transform: translateY(1rem);
    transition: all 0.3s ease;
  }
  
  .card-link:hover .hover-text {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  /* Card Color Variations */
  .card-blue {
    background: linear-gradient(135deg, #2563EB, #1D4ED8);
  }
  
  .card-green {
    background: linear-gradient(135deg, #059669, #047857);
  }
  
  .card-purple {
    background: linear-gradient(135deg, #7C3AED, #6D28D9);
  }
  
  .card-gold {
    background: linear-gradient(135deg, #D97706, #B45309);
  }
  
  /* Hover Effects */
  .card-link::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .card-link:hover::after {
    opacity: 1;
  }
  
  .hovered {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .responsive-title {
    font-size: 24px; /* Dimensiune implicită */
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .responsive-title {
      font-size: 18px; /* Dimensiune pentru tablete */
    }
  }
  
  @media (max-width: 480px) {
    .responsive-title {
      font-size: 16px; /* Dimensiune pentru telefoane */
    }
  }

  .font-size--x--small {
    font-size: 10px;
  }

  .font-size--small {
    font-size: 14px;
  }
  
  .font-size--default {
    font-size: 19px;
  }
  
  .font-size--large {
    font-size: 24px;
  }

  .font-size--extra--x--large{
      font-size: 28px;
  }
  .font-size--extra--large {
    font-size: 32px;
  }

  .contrast{
      background-color: black;
      
  }
  .font-color{
    color:yellow;
  }
/* Stil general pentru text */
.navbar-nav .nav-link,
.dropdown-menu .dropdown-item {
    font-family: Arial, sans-serif;
    font-size: 16px;
}

/* Stil pentru hover pe dropdown */
.dropdown-menu .dropdown-item:hover {
    background-color: #f8f8f8;
    color: #f1ebdd;
}

.dropdown-menu {
    margin-top: 0; /* Elimină decalajele care pot cauza probleme */
    padding: 5px; /* Ajustează pentru mai mult spațiu */
}

.dropdown:hover > .dropdown-menu {
    display: block; /* Asigură afișarea submeniului la hover */
}

/* Aliniere verticală a meniului */
.navbar-nav {
    align-items: center;
}

/* Linie dinamică sub meniul principal */
.navbar-nav .nav-link {
    position: relative;
    color: #5f5f5f; /* Culoare implicită */
    text-decoration: none;
}

.navbar-nav .nav-link:hover {
    color: #cca876 !important; /* Culoare la hover */
}

/* Stil pentru nav-item-custom */
.nav-item-custom {
    border: 2px solid #cca876;
    padding: 0px 30px;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: transparent; /* Fundal implicit */
}

.nav-item-custom a.nav-link-custom2 {
    color: #cca876 !important; /* Culoare text implicit */
    font-weight: bold !important;
    text-decoration: none;
    transition: color 0.3s ease; /* Efect de tranziție */
}

/* Hover efect pentru nav-item-custom */
.nav-item-custom:hover {
    background-color: #cca876; /* Fundal la hover */
    cursor: pointer;
}

.nav-item-custom:hover a.nav-link-custom2 {
    color: #fafafa !important; /* Text alb la hover */
}

/* Hover general pentru toate link-urile */
a:hover {
    color: #cca876;
    text-decoration: none;
}

/* Stil responsiv pentru hover */
@media only screen and (max-width: 768px) {
    a:hover {
        color: #cca876;
    }
}

/* Dropdown hover */
.navbar-nav .dropdown-menu {
    border: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.dropdown-menu .dropdown-item {
    color: #5f5f5f;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #aa9f89;
    color: #ffffff;
}

/* Linia activă din meniul principal */
.navbar-nav .nav-link.active {
    color: #aa9f89 !important;
    font-weight: bold;
}

/* Stiluri pentru telefon, email și fax */
.navbar-contact a {
    color: #5f5f5f;
    text-decoration: none;
    transition: color 0.3s ease;
}

.navbar-contact a:hover {
    color: #cca876;
}

/* Media Query pentru meniul responsive */
@media only screen and (max-width: 768px) {
    .nav-item-custom {
        padding: 5px 20px; /* Ajustare padding */
    }

    .navbar-nav .nav-link {
        font-size: 14px; /* Dimensiune text mai mică */
    }
}
